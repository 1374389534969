<template lang="pug">
div(
    class="order-payment"
    v-if="!!order"
)
    //- name bar
    TopBar
        IonText(
            color="secondary"
        )
            h1 Zahlung

    //- boxes
    Container
        //- style="background-color: var(--ion-color-white)"
        Spacer(
            small
        )
        IonRow(class="receding")
            IonCol(
                size="6"
                class="d-flex flex-column"
            )
                IonText(
                    color="primary"
                )
                    h3(class="with-margin") Kunde
                Box(
                    style="background-color: var(--ion-color-quaternary)"
                )
                    IonText(
                        color="primary"
                    )
                        div(class="mb-2") Frau Karin Weimann
                        div Wielandstrasse 13
                        div 70130 Stuttgart

            IonCol(
                size="6"
                class="d-flex flex-column"
            )
                IonText(
                    color="primary"
                )
                    h3(class="with-margin") Rapportdaten
                Box(
                    style="background-color: var(--ion-color-quaternary)"
                )
                    IonText(
                        color="primary"
                    )
                        div(class="mb-2") Monteur:
                        div Datum:

    //- jobs
    div
        OrderLines(
            :order="order"
            noAddButton
            noEditButtons
            light
            priceTags
        )

    //- payment boxes and button
    div(
        style="height: 420px"
    )
        //- :style="paymentSuccess ? { height: '420px' } : { height: '280px' }"
        //- div(class="page-buttons-wrapper")
        //-     Container
        //-         Spacer(
        //-             small
        //-         )
        //-         IonRow(class="receding")
        //-             IonCol(
        //-                 size="6"
        //-                 class="d-flex flex-column"
        //-             )
        //-                 div(
        //-                     style="background-color: var(--ion-color-primary); padding-top: 10px; height: 100%"
        //-                     class="radius"
        //-                 )
        //-                     IonText(
        //-                         color="quaternary"
        //-                         class="d-flex flex-column justify-center align-center"
        //-                         style="height: 100%; width: 100%"
        //-                     )
        //-                         div(
        //-                             style="padding: 0 10px 10px; border-bottom: 1px dashed var(--ion-color-quaternary); width: 100%"
        //-                         ) Total
        //-                         div(
        //-                             class="d-flex align-center"
        //-                             style="flex-grow: 1; width: 100%"
        //-                         )
        //-                             div(
        //-                                 class="d-flex align-start"
        //-                                 style="padding: 20px 10px 20px"
        //-                             )
        //-                                 div(
        //-                                     style="font-size: 25px"
        //-                                     class="mr-2"
        //-                                 ) €
        //-                                 div(
        //-                                     style="font-size: 40px"
        //-                                 ) {{ order.doctotal.toFixed(2) }}

        //-             IonCol(
        //-                 size="6"
        //-                 class="d-flex flex-column"
        //-             )
        //-                 div(
        //-                     style="background-color: var(--ion-color-quaternary); padding-top: 10px; height: 100%"
        //-                     class="radius"
        //-                 )
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         div(
        //-                             style="padding: 0 10px 10px; border-bottom: 1px dashed var(--ion-color-primary)"
        //-                         ) Zahlart wählen
        //-                         div(
        //-                             style="padding: 20px 10px 20px"
        //-                         )
        //-                             IonRadioGroup(
        //-                                 :value="paymentMethod"
        //-                             )
        //-                                 div(class="d-flex align-center mb-3")
        //-                                     IonRadio(
        //-                                         value="ec"
        //-                                         mode="md"
        //-                                         :disabled="paymentSuccess"
        //-                                     )
        //-                                     h3(class="ml-2") EC - Cash

        //-                                 div(class="d-flex align-center mb-3")
        //-                                     IonRadio(
        //-                                         value="cash"
        //-                                         mode="md"
        //-                                         :disabled="paymentSuccess"
        //-                                     )
        //-                                     h3(class="ml-2") Cash

        //-                                 div(class="d-flex align-center")
        //-                                     IonRadio(
        //-                                         value="invoice"
        //-                                         mode="md"
        //-                                         :disabled="true"
        //-                                     )
        //-                                         //- :disabled="paymentSuccess"
        //-                                     h3(class="ml-2") Invoice

        //-         IonRow(class="receding btn-container")
        //-             IonCol(
        //-                 size="12"
        //-                 class="pb-0"
        //-                 v-if="!paymentSuccess"
        //-             )
        //-                 IonButton(
        //-                     color="secondary"
        //-                     size="large"
        //-                     expand="block"
        //-                     @click="pay"
        //-                 )
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         h3(class="text-center") Jetzt bezahlen
        //-             IonCol(
        //-                 size="12"
        //-                 class="pb-0"
        //-                 v-if="!!paymentSuccess"
        //-             )
        //-                 IonButton(
        //-                     color="secondary"
        //-                     size="large"
        //-                     expand="block"
        //-                     @click="receiptSendModal = true"
        //-                 )
        //-                     IonText(
        //-                         color="white"
        //-                     )
        //-                         h3(class="text-center") Beleg per e-mail senden
        //-             IonCol(
        //-                 size="12"
        //-                 class="pb-0"
        //-                 v-if="!!paymentSuccess"
        //-             )
        //-                 IonButton(
        //-                     color="secondary"
        //-                     size="large"
        //-                     expand="block"
        //-                 )
        //-                     IonText(
        //-                         color="white"
        //-                     )
        //-                         h3(class="text-center") Beleg drucken
        //-             IonCol(
        //-                 size="12"
        //-                 class="pb-0"
        //-                 v-if="!!paymentSuccess"
        //-             )
        //-                 IonButton(
        //-                     color="secondary"
        //-                     size="large"
        //-                     expand="block"
        //-                     @click="finishOrder"
        //-                 )
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         h3(class="text-center") Auftrag abschliessen

    CustomModal(
        v-model="receiptSendModal"
    )
        EmailSendModalContent(
            :order="order"
            @sendEmail="sendReceipt"
            text="Belegen per e-mail senden an"
        )
</template>

<script>
import Box from "@/components/molecules/Box"
import TopBar from "@/components/sections/TopBar"
import OrderLines from "@/components/molecules/OrderLines"
import CustomModal from "@/components/molecules/modals/CustomModal"
import EmailSendModalContent from "@/components/molecules/modals/EmailSendModalContent"
import { ref } from "vue"
import { useUtilities } from "@/composables/utilities"

export default {
    components: { TopBar, Box, OrderLines, CustomModal, EmailSendModalContent },
    props: {
        order: {
            required: true
        }
    },
    setup(props) {
        // bus
        const emitter = useUtilities().getEmitter()

        const receiptSendModal = ref()

        function sendReceipt() {
            receiptSendModal.value = false

            emitter.emit("setLoadingModal", true)
            setTimeout(() => {
                emitter.emit("setLoadingModal", false)
            }, 1000)
            setTimeout(() => {
                emitter.emit("showSuccessModal")
            }, 1000)
        }

        emitter.on("openReceiptSendModal", () => {
            receiptSendModal.value = true
        })

        return {
            receiptSendModal,
            sendReceipt
        }
    }
}
</script>

<style lang="sass" scoped>
.order-payment
    ion-radio
        --color: var(--ion-color-primary)
        --color-checked: var(--ion-color-secondary)
</style>