<template lang="pug">
div(
    class="radius d-flex justify-space-between overflow-hidden"
    style="background-color: var(--ion-color-accent); width: 100%"
)
    //- left
    div(
        style="flex-grow: 1"
    )
        div(
            style="border-bottom: 1px dashed var(--ion-color-tertiary); width: 100%; padding: 15px 10px"
        )
            IonText(
                color="tertiary"
            )
                h3 {{ header }}

        div(
            style="padding: 20px 10px"
        )
            IonText(
                color="white"
            )
                div(class="mb-2") Frau Karin Weimann
                div Wielandstrasse 13
                div 70130 Stuttgart

    //- right
    div(
        style="background-color: var(--ion-color-primary); width: 100px; min-height: 100px"
        class="d-flex justify-center align-center"
    )
        CustomCheckableIcon(
            :checked="checked"
            @click="$emit('update:checked', !checked)"
        )
</template>

<script>
import CustomCheckableIcon from "@/components/molecules/CustomCheckableIcon"
export default {
    components: { CustomCheckableIcon },
    props: {
        checked: Boolean,
        header: String
    }
}
</script>