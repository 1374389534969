<template lang="pug">
div(
    style="width: 100%; margin-inline: 10px"
    color="transparent"
)
    IonText(
        color="white"
    )
        h2(class="mb-3") {{ text }}
    EmailSendPerson(
        v-model:checked="person1"
        header="Kunden"
        class="mb-3"
    )
    EmailSendPerson(
        v-model:checked="person2"
        header="Auftraggeber"
        class="mb-3"
    )

    IonButton(
        color="secondary"
        size="large"
        expand="block"
        @click="$emit('sendEmail')"
    )
        IonText(
            color="primary"
        )
            h3 Senden
</template>

<script>
import EmailSendPerson from "@/components/molecules/EmailSendPerson"
export default {
    components: { EmailSendPerson },
    data: () => ({
        person1: false,
        person2: false
    }),
    props: {
        order: {
            required: true,
            type: Object
        },
        text: {
            type: String,
            default: "Rer e-mail senden an"
        }
    }
}
</script>